<template>
  <div>
    <Pane />
    <div class="container">
      <a-form
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 16 }"
        :colon="false"
        @submit="handleSubmit"
        :form="form"
      >
        <a-row>
          <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="实习部门">
                <a-select
                v-model="form.deptId"
                show-search
                :filter-option="$selectFilterOption"
                style="width: 100%"
                v-decorator="[
                  'deptId',
                  { rules: [{ required: true, message: '请选择！' }] },
                ]"
              >
                <a-select-option
                  v-for="item in organizationList"
                  :key="item.id"
                  :value="item.id"
                  >{{ item.uniqueName }}</a-select-option
                >
              </a-select>
              </a-form-item>
          </a-col>
          <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="对接人">
                <EmployeeSelector
                  style="width: 100%"
                  @change="
                    (arr) => {
                      if (arr.length > 0) {
                        master = arr[0];
                      } else {
                        master = {};
                      }
                    }
                  "
                  :value="master.name ? [master] : []"
                >
                  <a-button block style="text-align: left">
                    <div v-if="master.name">
                      {{ master.name }}
                    </div>
                    <div v-else>&nbsp;</div>
                  </a-button>
                </EmployeeSelector>
              </a-form-item>
          </a-col>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="实习时段">
              <a-range-picker
                :placeholder="['开始时间', '结束时间']"
                v-decorator="[
                  'internDate',
                  { rules: [{ required: true, message: '请选择！' }] },
                ]"
                style="width: 100%"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="实习生姓名">
                <a-input
                  v-decorator="[
                    'name',
                    { rules: [{ required: true, message: '请输入！' }] },
                  ]"
                />
              </a-form-item>
          </a-col>
          <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="实习生手机号">
                <a-input
                  v-decorator="[
                    'mobile',
                    { rules: [{ required: true, message: '请输入！' }] },
                  ]"
                />
              </a-form-item>
          </a-col>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="出生年月">
              <a-date-picker
                v-decorator="[
                  'birthday',
                  { rules: [{ required: true, message: '请选择！' }] },
                ]"
                style="width: 100%"
              />
            </a-form-item>
          </a-col>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="性别">
              <a-radio-group
                v-decorator="[
                  'sex',
                  { rules: [{ required: true, message: '请选择！' }] },
                ]"
              >
                <a-radio
                  v-for="item in sex"
                  :key="item.value"
                  :value="item.value"
                  >{{ item.name }}</a-radio
                >
              </a-radio-group>
            </a-form-item>
          </a-col>
          <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="本科学校">
                <a-input
                  v-decorator="[
                    'bachelorSchool'
                  ]"
                />
              </a-form-item>
          </a-col>
          <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="本科专业">
                <a-input
                  v-decorator="[
                    'bachelorMajor'
                  ]"
                />
              </a-form-item>
          </a-col>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="本科在校时段">
              <a-range-picker
                :placeholder="['开始时间', '结束时间']"
                format="YYYY-MM"
                v-decorator="[
                  'bachelorDate',
                ]"
                style="width: 100%"
              />
            </a-form-item>
          </a-col>
          <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="硕士学校">
                <a-input
                  v-decorator="[
                    'masterSchool'
                  ]"
                />
              </a-form-item>
          </a-col>
          <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="硕士专业">
                <a-input
                  v-decorator="[
                    'masterMajor'
                  ]"
                />
              </a-form-item>
          </a-col>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="硕士在校时段">
              <a-range-picker
                :placeholder="['开始时间', '结束时间']"
                format="YYYY-MM"
                v-decorator="[
                  'masterDate',
                ]"
                style="width: 100%"
              />
            </a-form-item>
          </a-col>
          <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="其它说明">
                <a-textarea
                  v-decorator="[
                    'remark'
                  ]"
                />
              </a-form-item>
          </a-col>
        </a-row>
        <div class="center">
          <a-space>
            <a-button htmlType="submit" type="primary" :loading="loading">保存</a-button>
            <a-button @click="$close($route.path)">关闭</a-button>
          </a-space>
        </div>
      </a-form>
    </div>
  </div>
</template>

<script>
import organization from "@/mixins/organization";
import EmployeeSelector from "@/components/employee-selector";
import { mapGetters } from "vuex";
import request from "@/api/request";

function add(data) {
  return request({
    url: "/user-service/intern/add",
    method: "post",
    data
  });
}

export default {
  name: "internAdd",

  mixins: [organization],
  components: {
    EmployeeSelector,
  },
  computed: {
    ...mapGetters("setting", ["findDataDict"]),
    sex() {
      return this.findDataDict("sex");
    },
  },
  data() {
    return {
      master: {},
      form: this.$form.createForm(this),
      loading: false
    };
  },

  methods: {
    handleSubmit(e) {
      e.preventDefault();

      this.form.validateFields((err, values) => {
        if (!err) {
          if (!this.master.name) {
            this.$message.error("请选择对接人！");
            return;
          }
          this.loading = true;
          const { internDate, bachelorDate, masterDate } = values;
          let internFrom, internTo, bachelorFrom, bachelorTo, masterFrom, masterTo;
          if (Array.isArray(internDate) && internDate.length === 2) {
            internFrom = internDate[0].format("YYYY-MM-DD");
            internTo = internDate[1].format("YYYY-MM-DD");
          }
          if (Array.isArray(bachelorDate) && bachelorDate.length === 2) {
            bachelorFrom = bachelorDate[0].format("YYYY-MM");
            bachelorTo = bachelorDate[1].format("YYYY-MM");
          }
          if (Array.isArray(masterDate) && masterDate.length === 2) {
            masterFrom = masterDate[0].format("YYYY-MM");
            masterTo = masterDate[1].format("YYYY-MM");
          }
          delete values.internDate
          delete values.bachelorDate
          delete values.masterDate
          add({
            ...values,
            contactUserId: this.master.userId,
            contact: this.master.name,
            deptName: this.organizationList.filter(v=>v.id == values.deptId)[0].uniqueName,
            internFrom: internFrom,
            internTo: internTo,
            bachelorFrom: bachelorFrom,
            bachelorTo: bachelorTo,
            masterFrom: masterFrom,
            masterTo: masterTo,
            birthday: values.birthday ? values.birthday.format("YYYY-MM-DD") : ''
          })
            .then(() => {
              this.$close(this.$route.path);
            })
            .finally(() => {
              this.loading = false;
            });

         
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;
  padding: 24px;
}

.center {
  padding: 40px;
}
</style>
