<template>
  <div>
    <div style="font-weight: bold;margin-bottom:12px;font-size: 17px;">基本信息</div>
    <div class="panel">
      <div class="label">申请人</div>
      <div class="value1">{{ extra.applicant }}</div>
      <div class="label">申请部门</div>
      <div class="value2">{{ extra.applicantDept }}</div>
      <div class="label">项目名称</div>
      <div class="value1">{{ extra.projectName }}</div>
      <div class="label">申请理由</div>
      <div class="value2">{{ extra.remark }}</div>
    </div>
    <Padding />
    <div style="margin-top:12px;margin-bottom:12px;font-weight: bold;font-size: 17px;">CA锁信息</div>
    <div class="panel">
      <div class="label">新CA锁</div>
      <div class="value3"> <span v-if="extra.isNewLock!==undefined">{{ extra.isNewLock?'是':'否' }}</span> </div>
      <div class="label">CA锁名称</div>
      <div class="value3">{{ extra.caLock }}</div>
      <div class="label">借用期限</div>
      <div class="value3"><span v-if="startDate">{{ startDate }} ~ {{ endDate }}</span></div>
    </div>
    <Padding />
    <div style="margin-top:12px;margin-bottom:12px;font-weight: bold;font-size: 17px;">备案信息</div>
    <div class="panel">
      <div class="label">备案咨询电话</div>
      <div class="value1">{{ extra.filingPhone }}</div>
	  <div class="label">备案联系人信息</div>
      <div class="value2">{{ extra.filingContact }}</div>
      <div class="label">备案部门、网址</div>
      <div class="value1">{{ extra.filingUrl }}</div>
      <div class="label">备案所需材料</div>
      <div class="value2">{{ extra.filingMaterial }}</div>	  
    </div>
    <Padding />
  </div>
</template>
    
  <script>
  import { mapState } from "vuex";
  import { getName } from "@/utils/clock";
  import moment from "moment";
  export default {
    computed: {
      ...mapState("approval", ["detail"]),
      extra() {
        return this.detail.extra
          ? {
              ...this.detail.extra,
            }
          : {};
      },
      startDate() {
        return this.extra.periodBegin ? moment(this.extra.periodBegin).format("YYYY-MM-DD") : ''
      },
      endDate() {
        return this.extra.periodEnd ? moment(this.extra.periodEnd).format("YYYY-MM-DD") : ''
      }
    },
    methods: {
      getName,
  
      renderStaff(str) {
        let msg = "";
        try {
          const list = JSON.parse(str);
          msg = list.map((item) => item.userName).join("，");
        } catch (error) {
          msg = str;
        }
  
        return msg;
      },
    },
  };
  </script>
    
    
  <style lang="less" scoped>
  .panel {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    border-top: 1px solid #e8e8e8;
  
    & > div {
      padding: 8px;
      border-bottom: 1px solid #e8e8e8;
    }
  
    .label {
      background-color: #fafafa;
      border-right: 1px solid #e8e8e8;
      border-left: 1px solid #e8e8e8;
    }
  
    .value1 {
      grid-column-start: 2;
      grid-column-end: 4;
    }
  
    .value2 {
      grid-column-start: 5;
      grid-column-end: 7;
      border-right: 1px solid #e8e8e8;
    }
  
    .value3 {
      grid-column-start: 2;
      grid-column-end: 7;
      border-right: 1px solid #e8e8e8;
    }
  }
  </style>
    
    