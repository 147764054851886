<template>
  <div>
    <Pane />

    <div class="container">
      <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 12 }" :colon="false" @submit="handleSubmit" :form="form">
        <a-row>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="分类">
              <a-select style="width: 100%" v-model="activeCategoryIndex">
                <a-select-option v-for="(item, index) in categoryList" :key="index" :value="index">{{ item
                }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="子分类">
              <a-select style="width: 100%" v-decorator="[
                'pid',
                { rules: [{ required: true, message: '请选择！' }] },
              ]">
                <a-select-option v-for="item in typeList" :key="item.id" :value="item.id">{{ item.subCategory
                }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="名称">
              <a-input v-decorator="[
                'name',
                { rules: [{ required: true, message: '请输入！' }] },
              ]" />
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24" v-if="activeCategoryIndex == 7">
            <a-form-item label="专业类别">
              <a-input v-decorator="[
                'major',
                { rules: [{ required: true, message: '请输入！' }] },
              ]" />
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24" v-if="activeCategoryIndex == 7">
            <a-form-item label="设计编号">
              <a-input v-decorator="[
                'designCode',
                { rules: [{ required: true, message: '请输入！' }] },
              ]" />
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24" v-if="activeCategoryIndex == 7">
            <a-form-item label="项目负责人">
              <a-input v-decorator="[
                'charger',
                { rules: [{ required: true, message: '请输入！' }] },
              ]" />
            </a-form-item>
          </a-col>

          <!-- 证书 -->
          <template v-if="form.getFieldValue('pid') === '1'">
            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="等级">
                <a-select style="width: 100%" v-decorator="['level']">
                  <a-select-option v-for="item in cert_level" :key="item.value" :value="item.value">{{ item.name
                  }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="取得日期">
                <a-date-picker placeholder="" v-decorator="['dateAchieve']" style="width: 100%" />
              </a-form-item>
            </a-col>
          </template>

          <!-- 质量体系文件 -->
          <template v-if="form.getFieldValue('pid') === '2'">
            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="类型">
                <a-select style="width: 100%" v-decorator="['type']">
                  <a-select-option v-for="item in doc_document_type" :key="item.value" :value="item.value">{{ item.name
                  }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="编号">
                <a-input v-decorator="['code']" />
              </a-form-item>
            </a-col>

            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="版本">
                <a-input v-decorator="['version']" />
              </a-form-item>
            </a-col>
          </template>
          <!-- 质量管理制度 -->
          <template v-if="form.getFieldValue('pid') === '4'">
            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="发文号">
                <a-input v-decorator="['publishNum']" />
              </a-form-item>
            </a-col>

            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="发布日期">
                <a-date-picker placeholder="" v-decorator="['datePublish']" style="width: 100%" />
              </a-form-item>
            </a-col>
          </template>

          <!-- 科研成果 -->
          <template v-if="form.getFieldValue('pid') === '5'">
            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="主题词">
                <a-input v-decorator="['subject']" />
              </a-form-item>
            </a-col>
            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="项目负责人">
                <a-input v-decorator="['charger']" />
              </a-form-item>
            </a-col>
            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="主承担部门">
                <a-input v-decorator="['chargerDept']" />
              </a-form-item>
            </a-col>

            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="立项日期">
                <a-date-picker placeholder="" v-decorator="['dateSetup']" style="width: 100%" />
              </a-form-item>
            </a-col>
            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="完成日期">
                <a-date-picker placeholder="" v-decorator="['dateFinish']" style="width: 100%" />
              </a-form-item>
            </a-col>

            <a-col :lg="24" :md="24" :sm="24">
              <a-form-item label="科研成果" :label-col="{ span: 3 }" :wrapper-col="{ span: 18 }">
                <a-button type="primary" @click="addAchievement">添加</a-button>

                <Padding />

                <Achievement :visible="achievementVisible" :list="achievementList" :text="editingAchievement"
                  :index="editingAchievementIndex" :doc_achievement_type="doc_achievement_type"
                  @cancel="achievementVisible = false" @change="(list) => (achievementList = list)" />

                <a-table bordered :data-source="achievementList" :pagination="false">
                  <a-table-column title="名称" data-index="name" />
                  <a-table-column title="类型">
                    <template slot-scope="text">
                      <DataDictFinder dictType="doc_achievement_type" :dictValue="text.type" />
                    </template>
                  </a-table-column>
                  <a-table-column title="说明" data-index="remark" />
                  <a-table-column title="附件">
                    <template slot-scope="text">
                      <div v-if="Array.isArray(text.attachmentList)">
                        <a target="_blank" v-for="(item, index) in text.attachmentList" :key="index"
                          :href="item.completePath" style="padding-right: 8px">{{ item.name }}</a>
                      </div>
                    </template>
                  </a-table-column>
                  <a-table-column title="操作" align="center">
                    <template slot-scope="text, record, index">
                      <a-space>
                        <a href="#" @click.prevent="editAchievement(text, index)">编辑</a>
                        <a href="#" class="danger" @click.prevent="removeAchievement(index)">删除</a>
                      </a-space>
                    </template>
                  </a-table-column>
                </a-table>
              </a-form-item>
            </a-col>
          </template>

          <!-- 专利：发明/实用新型/外观 -->
          <template v-if="['6', '7', '8'].includes(form.getFieldValue('pid'))">
            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="专利号">
                <a-input v-decorator="['number']" />
              </a-form-item>
            </a-col>

            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="专利权人">
                <a-input v-decorator="['patentee']" />
              </a-form-item>
            </a-col>
            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="发明人">
                <a-input v-decorator="['inventor']" />
              </a-form-item>
            </a-col>

            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="专利申请日">
                <a-date-picker placeholder="" v-decorator="['dateApply']" style="width: 100%" />
              </a-form-item>
            </a-col>
            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="专利授权日">
                <a-date-picker placeholder="" v-decorator="['dateAuthorization']" style="width: 100%" />
              </a-form-item>
            </a-col>

            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="是否有效">
                <a-select style="width: 100%" v-decorator="['inforce']">
                  <a-select-option v-for="item in booleanList" :key="item.value" :value="item.value">{{ item.name
                  }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </template>

          <!-- 著作权 -->
          <template v-if="form.getFieldValue('pid') === '9'">
            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="登记号">
                <a-input v-decorator="['number',{ rules: [{ required: true, message: '请输入！' }] },]" />
              </a-form-item>
            </a-col>

            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="作品类别">
                <a-select style="width: 100%" v-decorator="['type']">
                  <a-select-option v-for="item in doc_works_cate" :key="item.value" :value="item.value">{{ item.name
                  }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="作者">
                <a-input v-decorator="['authorName']" />
              </a-form-item>
            </a-col>
            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="著作权人">
                <a-input v-decorator="['copyrightOwner']" />
              </a-form-item>
            </a-col>
            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="登记状态">
                <a-select style="width: 100%" v-decorator="['registration']">
                  <a-select-option v-for="item in doc_registration" :key="item.value" :value="item.value">{{ item.name
                  }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="创作完成日期">
                <a-date-picker placeholder="" v-decorator="['dateCompletion']" style="width: 100%" />
              </a-form-item>
            </a-col>
            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="初次发布日期">
                <a-date-picker placeholder="" v-decorator="['dateFirstPublish']" style="width: 100%" />
              </a-form-item>
            </a-col>
          </template>

          <!-- 工法 -->
          <template v-if="form.getFieldValue('pid') === '10'">
            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="工法编号">
                <a-input v-decorator="['code']" />
              </a-form-item>
            </a-col>

            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="批准文号">
                <a-input v-decorator="['approvalNumber']" />
              </a-form-item>
            </a-col>

            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="等级">
                <a-select style="width: 100%" v-decorator="['level']">
                  <a-select-option v-for="item in doc_law_level" :key="item.value" :value="item.value">{{ item.name
                  }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="工法完成人">
                <a-input v-decorator="['people']" />
              </a-form-item>
            </a-col>

            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="完成单位">
                <a-input v-decorator="['deptName']" />
              </a-form-item>
            </a-col>

            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="取得日期">
                <a-date-picker placeholder="" v-decorator="['dateHold']" style="width: 100%" />
              </a-form-item>
            </a-col>
          </template>
          <!-- 项目奖项 -->
          <template v-if="form.getFieldValue('pid') === '11'">
            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="获奖项目">
                <a-input v-decorator="['projectName']" />
              </a-form-item>
            </a-col>

            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="项目类型">
                <a-select style="width: 100%" v-decorator="['projectType']">
                  <a-select-option v-for="item in doc_prj_cate" :key="item.value" :value="item.value">{{ item.name
                  }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="项目负责人">
                <a-input v-decorator="['charger']" />
              </a-form-item>
            </a-col>

            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="奖项级别">
                <a-select style="width: 100%" v-decorator="['level']">
                  <a-select-option v-for="item in doc_award_level" :key="item.value" :value="item.value">{{ item.name
                  }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="获奖人员">
                <a-input v-decorator="['winner']" />
              </a-form-item>
            </a-col>
            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="评奖机构">
                <a-input v-decorator="['awardOrg']" />
              </a-form-item>
            </a-col>

            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="获奖日期">
                <a-date-picker placeholder="" v-decorator="['dateAward']" style="width: 100%" />
              </a-form-item>
            </a-col>
          </template>
          <!-- 企业荣誉 -->
          <template v-if="form.getFieldValue('pid') === '12'">
            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="荣誉等级">
                <a-select style="width: 100%" v-decorator="['level']">
                  <a-select-option v-for="item in doc_award_level" :key="item.value" :value="item.value">{{ item.name
                  }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="获奖日期">
                <a-date-picker placeholder="" v-decorator="['dateAward']" style="width: 100%" />
              </a-form-item>
            </a-col>

            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="评奖机构">
                <a-input v-decorator="['awardOrg']" />
              </a-form-item>
            </a-col>
          </template>
          <!-- 专家库 -->
          <template v-if="form.getFieldValue('pid') === '13'">
            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="专家类型">
                <a-input v-decorator="['expType']" />
              </a-form-item>
            </a-col>

            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="开始聘任日期">
                <a-date-picker placeholder="" v-decorator="['dateEngageFrom']" style="width: 100%" />
              </a-form-item>
            </a-col>

            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="截至聘任日期">
                <a-date-picker placeholder="" v-decorator="['dateEngageTo']" style="width: 100%" />
              </a-form-item>
            </a-col>

            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="擅长领域">
                <a-input v-decorator="['expArea']" />
              </a-form-item>
            </a-col>
          </template>
          <!-- 企业标准图集 -->
          <template v-if="form.getFieldValue('pid') === '14'">
            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="图集编号">
                <a-input v-decorator="['number']" />
              </a-form-item>
            </a-col>

            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="完成单位">
                <a-input v-decorator="['orgComplete']" />
              </a-form-item>
            </a-col>

            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="专业类型">
                <a-select style="width: 100%" v-decorator="['major']">
                  <a-select-option v-for="item in doc_law_level" :key="item.value" :value="item.value">{{ item.name
                  }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="发布日期">
                <a-date-picker placeholder="" v-decorator="['datePublish']" style="width: 100%" />
              </a-form-item>
            </a-col>
          </template>
          <!-- 主编标准/参编标准 -->
          <template v-if="form.getFieldValue('pid') === '15' ||
            form.getFieldValue('pid') === '16'
            ">
            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="标准编号">
                <a-input v-decorator="['number']" />
              </a-form-item>
            </a-col>

            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="参编人">
                <a-input v-decorator="['drafter']" />
              </a-form-item>
            </a-col>

            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="类型">
                <a-select style="width: 100%" v-decorator="['type']">
                  <a-select-option v-for="item in doc_std_level" :key="item.value" :value="item.value">{{ item.name
                  }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="专业类型">
                <a-select style="width: 100%" v-decorator="['major']">
                  <a-select-option v-for="item in majorTypes" :key="item.value" :value="item.value">{{ item.name
                  }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="发布日期">
                <a-date-picker placeholder="" v-decorator="['datePublish']" style="width: 100%" />
              </a-form-item>
            </a-col>

            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="实施日期">
                <a-date-picker placeholder="" v-decorator="['dateImplement']" style="width: 100%" />
              </a-form-item>
            </a-col>

            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="发布单位">
                <a-input v-decorator="['orgPublish']" />
              </a-form-item>
            </a-col>
          </template>

          <a-col :lg="24" :md="24" :sm="24" v-if="form.getFieldValue('pid') !== '5' &&
            form.getFieldValue('pid') !== '2'
            ">
            <a-form-item label="说明" :label-col="{ span: 3 }" :wrapper-col="{ span: 18 }">
              <a-textarea :auto-size="{ minRows: 3 }" v-decorator="['remark']" />
            </a-form-item>
          </a-col>

          <a-col :lg="24" :md="24" :sm="24" v-if="form.getFieldValue('pid') !== '5'">
            <a-form-item label="附件" :label-col="{ span: 3 }" :wrapper-col="{ span: 18 }">
              <Upload :list="attachmentList" @change="uploaded" />

              <Padding />

              <a-table bordered :data-source="attachmentList" :pagination="false">
                <a-table-column title="文件名">
                  <template slot-scope="text">
                    <a :href="text.completePath" target="_blank">{{
                      text.name
                    }}</a>
                  </template>
                </a-table-column>
                <a-table-column title="操作" align="center">
                  <template slot-scope="text, record, index">
                    <a href="#" class="danger" @click.prevent="remove(index)">删除</a>
                  </template>
                </a-table-column>
              </a-table>
            </a-form-item>
          </a-col>
        </a-row>

        <div class="center">
          <a-space>
            <a-button htmlType="submit" type="primary" :loading="loading">保存</a-button>
            <a-button @click="$close($route.path)">关闭</a-button>
          </a-space>
        </div>
      </a-form>
    </div>
  </div>
</template>

<script>
import { fetchCategory, add } from "@/api/knowledge";
import { mapGetters } from "vuex";
import Achievement from "./components/achievement.vue";
import Upload from "./components/upload";

export default {
  name: "addKnowledge",

  components: {
    Upload,
    Achievement,
  },

  data() {
    return {
      form: this.$form.createForm(this),
      attachmentList: [],

      category: {},
      categoryList: [],
      activeCategoryIndex: 0,

      achievementVisible: false,
      editingAchievement: {},
      editingAchievementIndex: -1,
      achievementList: [], // 科研成果

      loading: false,
    };
  },

  computed: {
    typeList() {
      const list = this.category[this.categoryList[this.activeCategoryIndex]];
      return Array.isArray(list) ? list : [];
    },

    ...mapGetters("setting", ["findDataDict"]),

    booleanList() {
      return this.findDataDict("boolean");
    },
    majorTypes() {
      return this.findDataDict("majorTypes");
    },

    doc_document_type() {
      return this.findDataDict("doc_document_type");
    },
    doc_achievement_type() {
      return this.findDataDict("doc_achievement_type");
    },
    doc_valid() {
      return this.findDataDict("doc_valid");
    },
    cert_level() {
      return this.findDataDict("cert_level");
    },
    doc_works_cate() {
      return this.findDataDict("doc_works_cate");
    },
    doc_law_level() {
      return this.findDataDict("doc_law_level");
    },
    doc_award_level() {
      return this.findDataDict("doc_award_level");
    },
    doc_std_level() {
      return this.findDataDict("doc_std_level");
    },
    doc_prj_cate() {
      return this.findDataDict("doc_prj_cate");
    },
    doc_registration() {
      return this.findDataDict("doc_registration");
    },
  },

  activated() {
    const { type } = this.$route.query;
    fetchCategory({
      field: type,
    }).then((res) => {
      if (res) {
        this.category = res;
        this.categoryList = Object.keys(res);
      }
    });
  },

  methods: {
    addAchievement() {
      this.editingAchievement = {};
      this.editingAchievementIndex = -1;
      this.achievementVisible = true;
    },
    editAchievement(text, index) {
      this.editingAchievement = text;
      this.editingAchievementIndex = index;
      this.achievementVisible = true;
    },
    removeAchievement(index) {
      this.achievementList.splice(index, 1);
    },

    uploaded(list) {
      this.attachmentList = list;
    },
    remove(index) {
      this.attachmentList.splice(index, 1);
    },

    handleSubmit(e) {
      e.preventDefault();

      this.form.validateFields((err, values) => {
        if (!err) {
          this.loading = true;
          add({
            ...values,
            dateAchieve: values.dateAchieve?.format("YYYY-MM-DD"),

            dateCompletion: values.dateCompletion?.format("YYYY-MM-DD"),
            dateFirstPublish: values.dateFirstPublish?.format("YYYY-MM-DD"),

            dateSetup: values.dateSetup?.format("YYYY-MM-DD"),
            dateFinish: values.dateFinish?.format("YYYY-MM-DD"),

            dateApply: values.dateApply?.format("YYYY-MM-DD"),
            dateAuthorization: values.dateAuthorization?.format("YYYY-MM-DD"),

            dateAward: values.dateAward?.format("YYYY-MM-DD"),
            dateEngageFrom: values.dateEngageFrom?.format("YYYY-MM-DD"),
            dateEngageTo: values.dateEngageTo?.format("YYYY-MM-DD"),

            dateImplement: values.dateImplement?.format("YYYY-MM-DD"),
            datePublish: values.datePublish?.format("YYYY-MM-DD"),

            dateHold: values.dateHold?.format("YYYY-MM-DD"),

            docQcResearchAchievmentList:
              this.achievementList.length > 0
                ? this.achievementList
                : undefined, // 科研成果

            attachmentList:
              this.attachmentList.length > 0 ? this.attachmentList : undefined,
          })
            .then(() => {
              this.$close(this.$route.path);
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;
  padding: 30px 164px;
}

.center {
  margin-top: 91px;
}
</style>
