import request from '../request'
import download from "@/api/download";
export function addScore(data) {
    return request({
        url: '/office-service/staffPerformance2024/login/add',
        method: 'POST',
        data,
    })
}

export function  fetchScore() {
    return request({
        url: '/office-service/staffPerformance2024/login/detail',
    })
}
export function  fetchScoreDetail(id) {
    return request({
        url: '/office-service/staffPerformance2024/login/detail/' + id,
    })
}

export function fetchList(params) {
    return request({
        url: '/office-service/check/list',
        params
    })
}

export function exportFile(id) {
    return download({
        url: '/office-service/check/export/' + id,
    })
}

export function fetchDetail(id) {
    return request({
        url: '/office-service/check/detail/' + id,
    })
}
export function fetchTopic(params) {
    return request({
        url: '/office-service/check/topic/list',
        params
    })
}


export function fetchEmployeeDetail(id) {
    return request({
        url: '/office-service/checkEmployee/detail/' + id,
    })
}

export function edit(data) {
    return request({
        url: '/office-service/check/update',
        method: 'POST',
        data,
    })
}

export function fetchScoreList() {
    return request({
        url: '/office-service/checkScorer/list'
    })
}

export function editScore(data) {
    return request({
        url: '/office-service/checkScorer/score',
        method: 'POST',
        data,
    })
}