<template>
  <div>
    <Pane />

    <a-card class="container">


      <Padding />

      <a-table
        bordered
        :dataSource="list"
        :loading="loading"
        @change="onChange"
        :pagination="{
          total,
          current,
          pageSize,
          showTotal: (total) => `共 ${total} 条记录`,
        }"
        rowKey="id"
      >
        <a-table-column title="序号" width="50px" align="center">
          <template slot-scope="text, row, index">
              {{ index + 1 }}
          </template>
        </a-table-column>
        <a-table-column title="年份" data-index="year"></a-table-column>
        <a-table-column title="考核时间" align="center">
          <template slot-scope="text">{{ text.startDate }}至{{ text.endDate }}</template>
        </a-table-column>
        <a-table-column align="center" title="操作" width="140px">
          <template slot-scope="text">
            <a-space>
              <a
                href="#"
                v-if="$getPermission($route.path + '/detail')"
                @click.prevent="
                  $router.push($route.path + '/detail?id=' + text.id)
                "
              >详情</a>
              <a
                href="#"
                v-if="$getPermission($route.path + '/edit')"
                @click.prevent="
                  $router.push($route.path + '/edit?id=' + text.id)
                "
              >编辑</a>
              <a
                href="#"
                v-if="$getPermission($route.path + '/edit')"
                @click.prevent="download(text.id)"
              >导出</a>
            </a-space>
          </template>
        </a-table-column>
      </a-table>
    </a-card>
  </div>
</template>

<script>
import { fetchList, exportFile} from "@/api/score";
import { saveAs } from "file-saver";

export default {


  data() {
    return {
      queryForm: {},

      loading: false,
      current: 1,
      pageSize: 10,
      list: [],
      total: 0
    };
  },

  mounted() {
    this.getList();
  },

  methods: {
    download(id){
      this.loading = true;
      exportFile(id)
        .then((blob) => {
          this.loading = false;
          saveAs(blob, "年度考核评分.xlsx");
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getList() {
      const { current, pageSize, queryForm } = this;

      this.loading = true;
      fetchList({
        pageNum: current,
        pageSize
      })
        .then(res => {
          this.loading = false;
          if (Array.isArray(res.list)) {
            this.list = res.list;
            this.total = res.totalSize;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },

    onChange(pagination) {
      this.current = pagination.current;
      this.pageSize = pagination.pageSize;
      this.getList();
    },
  }
};
</script>