<template>
  <div>
    <Pane />

    <a-card class="container">
      <a-form
        :form="form"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 18 }"
        :colon="false"
        @submit="handleSubmit"
      >
        <a-row>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="年份">
              <a-input :disabled="true"
                v-decorator="[
                  'year',
                  {
                    rules: [{ required: true, message: '请输入！' }],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="开始时间">
               <a-date-picker
                placeholder=""
                v-decorator="[
                  'startDate',
                  { rules: [{ required: true, message: '请选择！' }] },
                ]"
                style="width: 100%"
              />
            </a-form-item>
          </a-col>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="结束时间">
               <a-date-picker
                placeholder=""
                v-decorator="[
                  'endDate',
                  { rules: [{ required: true, message: '请选择！' }] },
                ]"
                style="width: 100%"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <div class="center">
          <a-space>
            <a-button type="primary" html-type="submit" :loading="saveLoading">确认修改</a-button>
            <a-button @click="$close($route.path)">关闭</a-button>
          </a-space>
        </div>
      </a-form>
    </a-card>
  </div>
</template>


<script>
import { fetchDetail, edit } from "@/api/score";
import moment from "moment";

export default {
  name: "scoreEdit",
  data() {
    return {
      id: null,
      form: this.$form.createForm(this),
      list: [],
      saveLoading: false,
    };
  },

  mounted() {
    console.log(123)
    const { query } = this.$route;
    const { id } = query || {};

    if (id && this.id !== id) {
      this.id = id;
      this.form.resetFields();
      this.list = [];
      this.getDetail();
    }
  },

  methods: {
    getDetail() {
      fetchDetail(this.id).then(res => {
        this.form.setFieldsValue({
          year: res.year,
          startDate: res.startDate,
          endDate: res.endDate
        });
      });
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log("values", values);
          this.saveLoading = true;
          edit({
            id: this.id,
            ...values,
            startDate: moment(values.startDate).format("YYYY-MM-DD"),
            endDate: moment(values.endDate).format("YYYY-MM-DD")
          })
            .then(() => {
              setTimeout(() => {
                this.saveLoading = false;
                this.$close(this.$route.path);
              }, 1000);
            })
            .finally(() => {
              this.saveLoading = false;
            });
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;
  padding: 24px 160px;
}

.list {
  .item {
    line-height: 2em;
    border: 1px solid #e0e0e0;
    padding: 12px;
    margin-top: 12px;
    position: relative;
    .title {
      font-size: 14px;
      font-weight: bold;
    }
    .subtitle {
      font-weight: bold;
    }
  }
}

.tool {
  position: absolute;
  top: 12px;
  right: 12px;
  .icon {
    cursor: pointer;
  }
}

.center {
  margin-top: 80px;
}
</style>