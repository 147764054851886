<template>
  <div>
    <Pane />

    <a-card class="container">
    <a-row>
      <a-col :lg="5" :md="24" :sm="24">
        <a-directory-tree multiple default-expand-all @select="onSelect" @expand="onExpand" style="height: 650px;overflow-y:scroll;width:250px;border:1px solid #ddd;padding:0 10px">
          <a-tree-node key="0-0" title="高管" :selectable="false">
            <a-tree-node v-for="item in tree.seniorExecutiveEmployeeList" :key="item.id" :title="item.userName" is-leaf/>
          </a-tree-node>
          <a-tree-node key="0-1" title="部门管理班子成员" :selectable="false">
            <a-tree-node v-for="item in tree.managementTeamEmployeeList" :key="item.deptName" :title="item.deptName" :selectable="false">
              <a-tree-node v-for="ii in item.employeeList" :key="ii.id" :title="ii.userName" is-leaf/>
            </a-tree-node>
          </a-tree-node>
        </a-directory-tree>
      </a-col>
      <a-col :lg="18" :offset="1" :md="24" :sm="24">
        <a-tabs >
          <a-tab-pane key="0" tab="平均分">
            <a-table
              bordered
              :dataSource="list.averageList"
              :loading="loading"
              :pagination="false"
              rowKey="id"
            >
              <a-table-column title="考核组" align="center">
                <template slot-scope="text">
                    <span v-if="text.role == 'generalManager'">总经理</span>
                    <span v-if="text.role == 'seniorExecutive'">高管</span>
                    <span v-if="text.role == 'deptLeader'">部门一把手</span>
                    <span v-if="text.role == 'charger'">部门分管</span>
                    <span v-if="text.role == 'employee'">部门员工</span>
                </template>
              </a-table-column>
              <a-table-column title="平均分" data-index="score" align="center"></a-table-column>
              <a-table-column title="考核人数" align="center">
                <template slot-scope="text">{{ text.completeCount }}/{{ text.count }}</template>
              </a-table-column>
            </a-table>
          </a-tab-pane>
          <a-tab-pane key="1" tab="评分情况">
            <a-table
              bordered
              :dataSource="list.fractionList"
              :loading="loading"
              :pagination="false"
              rowKey="id"
              :scroll="{ y: '570px' }"
            >
              <a-table-column title="分数" data-index="score" align="center"></a-table-column>
              <a-table-column title="理由" data-index="reason" align="center"></a-table-column>
              <a-table-column title="建议" data-index="proposal" align="center"></a-table-column>
            </a-table>
          </a-tab-pane>
          <a-tab-pane key="2" tab="考核人">
            <a-table
              bordered
              :dataSource="list.scorerList"
              :loading="loading"
              :pagination="false"
              rowKey="id"
              :scroll="{ y: '570px' }"
            >
              <a-table-column title="考核组" align="center">
                <template slot-scope="text">
                    <span v-if="text.role.split(',')[0] == 'generalManager'">总经理</span>
                    <span v-if="text.role.split(',')[0] == 'seniorExecutive'">高管</span>
                    <span v-if="text.role.split(',')[0] == 'deptLeader'">部门一把手</span>
                    <span v-if="text.role.split(',')[0] == 'charger'">部门分管</span>
                    <span v-if="text.role.split(',')[0] == 'employee'">部门员工</span>
                </template>
              </a-table-column>
              <a-table-column title="部门" data-index="deptName" align="center"></a-table-column>
              <a-table-column title="姓名" data-index="userName" align="center"></a-table-column>
            </a-table>
          </a-tab-pane>
          <a-tab-pane key="3" tab="题目">
            <a-table
                bordered
                :dataSource="topics"
                :loading="loading"
                :pagination="false"
                rowKey="id"
                :scroll="{ y: '570px' }"
              >

                <a-table-column title="题目" data-index="title" align="left"></a-table-column>
              </a-table>
          </a-tab-pane>
        </a-tabs>
        </a-col>
      </a-row>
    </a-card>
  </div>
</template>


<script>
import { fetchList, fetchDetail, fetchEmployeeDetail, fetchTopic} from "@/api/score";


export default {
  name: "scoreDetail",

  data() {
    return {
      loading: false,
      list: [],
      topics: [],
      tree: {}
    };
  },

  mounted() {
    const { query } = this.$route;
    const { id } = query || {};
    if (id) {
      fetchDetail(id).then(res => {
        this.tree = res;
      });
    }
  },

  methods: {

    onSelect(keys, event) {
      console.log('Trigger Select', keys, event);
      this.loading = true;
      fetchEmployeeDetail(keys[0])
        .then(res => {
          this.list = res;
        })
        .finally(() => {
          this.loading = false;
        });
      const arr = []
      this.tree.managementTeamEmployeeList.forEach(v=>{
        v.employeeList.forEach(i=>{
          arr.push(i)
        })
      })   
      console.log(arr)
      const alllist = this.tree.seniorExecutiveEmployeeList.concat(arr)
      const r = alllist.filter(v=>v.id==keys[0]);
      console.log(r)
      console.log(r[0].topicType)
      fetchTopic({topicType:r[0].topicType})
        .then(res => {
          this.topics = res;
        })
        .finally(() => {
          this.loading = false;
        });  
    },
    onExpand() {
      console.log('Trigger Expand');
    },
  }
};
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;
  padding: 24px 160px;
}

.list {
  .item {
    line-height: 2em;
    border: 1px solid #e0e0e0;
    padding: 12px;
    margin-top: 12px;
    position: relative;
    .title {
      font-size: 14px;
      font-weight: bold;
    }
    .subtitle {
      font-weight: bold;
    }
  }
}

.tool {
  position: absolute;
  top: 12px;
  right: 12px;
  .icon {
    cursor: pointer;
  }
}

.center {
  margin-top: 80px;
}
</style>