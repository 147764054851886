<template>
    <div>
        <Pane />

        <a-card class="container">

            <a-form :label-col="{ span: 8 }" :wrapper-col="{ span: 12 }" :colon="false" @submit="handleSubmit"
                :form="form">
                <div class="title" style="margin-top: 0">基本信息</div>
                <div class="wrapper">
                <a-row>
                    <a-col :lg="12" :md="12" :sm="24">
                        <a-form-item label="申请人">
                            <a-button block style="text-align: left">
                                {{
                user.userName
            }}
                            </a-button>
                        </a-form-item>
                    </a-col>

                    <a-col :lg="12" :md="12" :sm="24">
                        <a-form-item label="申请部门">
                            <a-button block style="text-align: left">
                                {{
                department.deptUniqueName
            }}
                            </a-button>
                        </a-form-item>
                    </a-col>
                    
                    <a-col :lg="12" :md="12" :sm="24">
                        <a-form-item label="项目名称">
                            <span style="color:red;position:absolute;left:-70px;top:2px">*</span>
                            <ProjectSelector
                                @change="onSelectProject"
                                :inspectUnitId="form.getFieldValue('deptId')"
                                >
                                <a-button
                                    block
                                    style="text-align: left"
                                >{{ selectedProject.projectName ? selectedProject.projectName : '选择' }}</a-button>
                            </ProjectSelector>
                        </a-form-item>
                    </a-col>
                     <a-col :lg="12" :md="12" :sm="24">
                        <a-form-item label="申请理由">
                            <a-textarea v-decorator="['remark']" />
                        </a-form-item>
                    </a-col>
                </a-row>
                </div>
                <div class="title" style="margin-top: 0">CA锁信息</div>
                <div class="wrapper">
                
                    <a-row>
                    <a-col :lg="12" :md="12" :sm="24">
                        <a-form-item label="新CA锁">
                            <a-radio-group v-decorator="['isNewLock']">
                                <a-radio
                                    v-for="item in [{
                                        name: '是',
                                        value: true
                                    }, {
                                        name: '否',
                                        value: false,
                                    }]"
                                    :key="item.value"
                                    :value="item.value"
                                    >{{ item.name }}
                                </a-radio>
                            </a-radio-group>
                        </a-form-item>
                    </a-col>
                    <a-col :lg="12" :md="12" :sm="24">
                        <a-form-item label="CA锁名称">
                            <a-input v-decorator="['caLock']" />
                        </a-form-item>
                    </a-col>

                    <a-col :lg="12" :md="12" :sm="24">
                        <a-form-item label="借用期限">
                            <a-range-picker style="width: 100%" :placeholder="[]" format="YYYY-MM-DD"
                                v-decorator="[
                'date'
            ]" />
                        </a-form-item>
                    </a-col>

                    

                   


                </a-row>
            </div>  
            <div class="title" style="margin-top: 0">备案信息</div>   
            <div class="wrapper">   
                <a-row>
                    <a-col :lg="12" :md="12" :sm="24">
                        <a-form-item label="备案咨询电话">
                            <a-input v-decorator="['filingPhone']" />
                        </a-form-item>
                    </a-col>
					 <a-col :lg="12" :md="12" :sm="24">
                        <a-form-item label="备案联系人信息">
                            <a-input v-decorator="['filingContact']" />
                        </a-form-item>
                    </a-col>
                    <a-col :lg="12" :md="12" :sm="24">
                        <a-form-item label="备案部门、网址">
                            <a-input v-decorator="['filingUrl']" />
                        </a-form-item>
                    </a-col>
					<a-col :lg="12" :md="12" :sm="24">
                        <a-form-item label="备案所需材料">
                            <a-input v-decorator="['filingMaterial']" />
                        </a-form-item>
                    </a-col>
                </a-row>
            </div>   
                <div class="footer">
                    <a-space>
                        <a-button type="primary" html-type="submit" :loading="saveLoading">提交</a-button>
                        <a-button @click="$close($route.path)">关闭</a-button>
                    </a-space>
                </div>
            </a-form>
        </a-card>
    </div>
</template>

<script>
import ProjectSelector from "./info-selector";
// import ProjectSelector from "@/components/project-selector";
import request from "@/api/request";
import organization from "@/mixins/organization";
import { mapGetters, mapState } from "vuex";

function save(data) {
    return request({
        url: "/office-service/oa/cafiling/add",
        method: "post",
        data
    });
}
export default {
    data() {
        return {
            form: this.$form.createForm(this),
            saveLoading: false,
            selectedProject: {}, // 选中的项目
        };
    },
    components: {
        ProjectSelector
    },
    mixins: [organization],
    computed: {
        ...mapState("auth", ["user"]),
        ...mapGetters("auth", ["department"]),
        ...mapGetters("setting", ["findDataDict"]),
        carList() {
            return this.findDataDict("oa_use_car_type");
        },
    },
    mounted() {

    },
    methods: {
        onSelectProject(project) {
        console.log(project);
        this.selectedProject = project;
        },
        handleSubmit(e) {
            e.preventDefault();
            console.log(this.department)
            if (!this.selectedProject.projectName) {
                this.$message.error("请选择项目");
                return;
            }
            const that = this;
            this.form.validateFields((err, values) => {
                if (!err) {
                    that.$confirm({
                        title: "本次提交将发起审批流程，是否继续？",
                        onOk() {
                            that.saveLoading = true;
                            const { date } = values;
                            let startDate, endDate;
                            if (Array.isArray(date) && date.length === 2) {
                                startDate = date[0].format("YYYY-MM-DD");
                                endDate = date[1].format("YYYY-MM-DD");
                            }
                            const json = {
                                ...values,
                                periodBegin: startDate,
                                periodEnd: endDate,
                                applicant: that.user.userName,
                                applicantId: that.department.userId,
                                applicantDeptId: that.department.deptId,
                                applicantDept: that.department.deptUniqueName,
                                projectId: that.selectedProject.id,
                                projectName: that.selectedProject.projectName,
                            }
                            delete json.date
                            save(json).then(() => {
                                that.$close(that.$route.path);
                            }).finally(() => {
                                that.saveLoading = false;
                            })
                        }
                    });

                }
            });
        }
    }
};
</script>

<style lang="less" scoped>
.pane {
    background-color: #fff;
    padding: 16px 32px;
    position: relative;

    .title {
        font-size: 18px;
        color: rgba(0, 0, 0, 0.85);
    }

    .remarks {
        margin-top: 8px;
        font-size: 13px;
        color: rgba(0, 0, 0, 0.45);
    }
}

.footer {
    padding: 80px 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.wrapper {
  background-color: #f9f9f9;
  padding-top: 24px;
  padding-bottom: 8px;
  margin-bottom:10px;
}
.title {
  font-weight: bold;
  font-size: 17px;
  margin: 12px 0;
}
.container {
  background-color: #fff;
  padding: 24px 160px;
}
</style>