<template>
  <div>
    <Pane />
    <a-modal width="500px" title="打分" :visible="dialogVisible" :footer="null" @cancel="dialogVisible = false">
      <a-form
        :label-col="{ span: 4 }"
        :wrapper-col="{ span: 14 }"
        :colon="false"
      >
        <a-row>


          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item label="部门">
              <a-input v-model="detail.checkEmployee.deptName" :disabled="true"/>
            </a-form-item>
          </a-col>
          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item label="姓名">
              <a-input v-model="detail.checkEmployee.userName" :disabled="true"/>
            </a-form-item>
          </a-col>

          
          <a-col :lg="24" :md="24" :sm="24" v-if="isTopic" style="border:1px solid #ddd;padding:5px 20px;margin-bottom:20px">
            <div class="sheet" v-for="(item, index) in topics" style="padding:10px 20px;align-items: center;border-bottom:1px solid #ddd">
              <div style="margin-bottom:5px;flex:1">{{item.title}}</div>
              <div style="width:80px">
                <a-radio-group v-model="item.score" style="margin:10px 0;width:500px">
                  <a-radio :value="5">特优</a-radio>
                  <a-radio :value="4">优</a-radio>
                  <a-radio :value="3">良</a-radio>
                  <a-radio :value="2">中</a-radio>
                  <a-radio :value="1">差</a-radio>
                </a-radio-group>
              </div>
            </div>
          </a-col>
          <a-col :lg="24" :md="24" :sm="24" v-else>
            <a-form-item label="分数">
              <a-input-number type="number" v-model="detail.score" :max="100" :min="0" @change="scoreChange"/>
            </a-form-item>
          </a-col>
          <a-col :lg="24" :md="24" :sm="24" v-if="!isTopic && reasonShow">
            <a-form-item label="理由">
              <a-textarea v-model="detail.reason"/>
            </a-form-item>
          </a-col>
          <a-col :lg="24" :md="24" :sm="24" v-if="!isTopic && reasonShow">
            <a-form-item label="建议">
              <a-textarea v-model="detail.proposal"/>
            </a-form-item>
          </a-col>
        </a-row>
        <div v-if="isTopic" style="margin-bottom:30px;color:red;text-align:center">请给每条打分，特优（5分）、优（4分）、良（3分）、中（2分）、差（1分）。</div>
        <div v-if="!isTopic" style="margin-bottom:30px;color:red;padding:0 83px;">每个人总分共100分, 低于80分请填写理由和建议。</div>
        <div class="center">
          <a-space>
              <a-button :loading="loading" type="primary" @click="score">确认</a-button>
              <a-button @click="dialogVisible = false">关闭</a-button>
          </a-space>
        </div>
      </a-form>
    </a-modal>
    <a-card class="container">


      <Padding />

      <a-table
        bordered
        :dataSource="list"
        :loading="loading"
        :pagination="false"
        rowKey="id"
      >
        <a-table-column title="序号" width="50px" align="center">
          <template slot-scope="text, row, index">
              {{ index + 1 }}
          </template>
        </a-table-column>
        <a-table-column title="部门" data-index="checkEmployee.deptName"></a-table-column>
        <a-table-column title="姓名" data-index="checkEmployee.userName"></a-table-column>
        <a-table-column title="分数" data-index="score"></a-table-column>
        <a-table-column title="理由" data-index="reason"></a-table-column>
        <a-table-column title="建议" data-index="proposal"></a-table-column>
        <a-table-column align="center" title="操作" width="140px">
          <template slot-scope="text,row">
            <a-space>
              <a
                href="#"
                @click.prevent="showDialog(row)"
              >打分</a>
            </a-space>
          </template>
        </a-table-column>
      </a-table>
    </a-card>
  </div>
</template>

<script>
import { fetchScoreList, editScore, fetchTopic} from "@/api/score";


export default {


  data() {
    return {
      dialogVisible: false,
      queryForm: {},
      isTopic: false,
      loading: false,
      current: 1,
      pageSize: 10,
      list: [],
      total: 0,
      detail: {
        checkEmployee:{}
      },
      reasonShow: false,
      topics: []
    };
  },

  mounted() {
    this.getList();
  },

  methods: {
    scoreChange(value){
      if(value < 80){
        this.reasonShow = true;
      }else{
        this.reasonShow = false;
      }
    },
    score() {
      if(!this.isTopic && this.detail.score !==0 && !this.detail.score){
        this.$message.error("请打分");
        return;
      }
      if(!this.isTopic && this.reasonShow){
        if(!this.detail.reason){
          this.$message.error("请填写理由");
          return;
        }
        if(!this.detail.proposal){
          this.$message.error("请填写建议");
          return;
        }
      }
      let scored = true;
      if(this.isTopic){
        this.topics.forEach(v=>{
          if(!v.score){
            scored = false
          }
        })
      }
      if(!scored){
        this.$message.error("请打分");
        return;
      }
      this.loading = true;
      editScore({
        id: this.detail.id,
        proposal: this.reasonShow?this.detail.proposal:'',
        reason: this.reasonShow?this.detail.reason:'',
        score: !this.isTopic?this.detail.score:null,
        detailList: this.isTopic?this.topics.map(v=>{
          return {
            topicId:v.id,
            score: v.score
          }
        }): null
      }).then(res => {
        this.getList();
        this.dialogVisible = false;
      }).finally(()=>{
        this.loading = false;
      })
    },
    showDialog(row){
      console.log(row)
      this.dialogVisible=true;
      if(row.checkEmployee.topicType == 0){
        this.isTopic = false;
      }else{
        this.isTopic = true;
        fetchTopic({topicType:row.checkEmployee.topicType})
        .then(res => {
          this.topics = res;
          this.topics = this.topics.map(v=>{
            return {
              ...v,
              score: ''
            }
          })
          row.detailList && row.detailList.forEach(v=>{
            this.topics.forEach(i=>{
              if(v.topicId == i.id){
                i.score = v.score
              }
            })
            
          })
          console.log(this.topics)
        }); 
      }
      if(row.score < 80){
        this.reasonShow = true;
      }else{
        this.reasonShow = false;
      }
      this.detail = JSON.parse(JSON.stringify(row));
    },
    getList() {
      const { current, pageSize, queryForm } = this;

      this.loading = true;
      fetchScoreList()
        .then(res => {
          this.loading = false;
          if (Array.isArray(res)) {
            this.list = res;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },

    onChange(pagination) {
      this.current = pagination.current;
      this.pageSize = pagination.pageSize;
      this.getList();
    },
  }
};
</script>
<style lang="less" scoped>
.sheet:nth-last-child(1){
  border:none!important;
}
</style>